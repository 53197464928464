import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import { H1, H2, H3 } from '../PageBuilder/Primitives/Heading';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);
const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

import FallbackPage, { fallbackSections } from './FallbackPage';
import { ASSET_NAME } from './TermsOfServicePage.duck';
import { ExternalLink, LayoutSingleColumn, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import css from './TermsOfServicePage.module.css';
import { isScrollingDisabled } from '../../ducks/ui.duck';

// This "content-only" component can be used in modals etc.
const TermsOfServiceContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};

// Presentational component for TermsOfServicePage
const TermsOfServicePageComponent = props => {
  const { pageAssetsData, inProgress, error, scrollingDisabled } = props;

  return (
    // <PageBuilder
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   fallbackPage={<FallbackPage />}
    // />

    <Page title={'Terms of Service Page'} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.pageSection}>
          <div className={css.section}>
            <H1 className={css.mainTitle}>Terms of Service</H1>


            <div className={css.list}>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>1</span>Introduction
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    Welcome to TherapAide. By accessing or using our platform, you agree to be bound by these Terms of Service ("Terms"). We reserve the right to modify these Terms at any time, and your continued use of the platform constitutes acceptance of the revised Terms.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>2</span>Account Creation and Maintenance
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    You must be a licensed professional to create an account on TherapAide. You are
                    responsible for maintaining the security of your account information and are liable for all
                    activities that occur under your account. We reserve the right to terminate accounts that
                    violate these Terms.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>3</span>Use of the Platform
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    You may use TherapAide only for lawful purposes and in accordance with these Terms.
                    Unauthorized use of the platform, including copying, distributing, or modifying content,
                    is prohibited.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>4</span>Purchases and Refunds
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    Sellers must certify their professional credentials and ensure that their listings are
                    accurate and lawful. Sellers are responsible for providing accurate descriptions and
                    necessary details about their products. TherapAide charges fees for its services, and
                    payment is processed according to the agreed schedule.
                  </li>

                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>5</span>Product Listings and Sales
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    By purchasing products on TherapAide, you agree to these Terms. Currently, we do not offer refunds for digital products. You can access your purchased products through your
                    account under "My Purchases."
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>6</span> Intellectual Property
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    Content uploaded by users remains their property, but you grant TherapAide a license to
                    use it on the platform. Third-party content is subject to the user's responsibility for
                    ensuring its lawful use.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>7</span> Confidentiality and Privacy
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    We are committed to protecting your data. Consultations are confidential unless they violate mandated reporting guidelines. Please review our Privacy Policy for more details.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>8</span>Disclaimers and Limitation of Liability
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    TherapAide does not guarantee the accuracy or reliability of user-provided content. We
                    are not responsible for users acting outside their scope of practice or for
                    non-professionals using resources incorrectly. Our liability for damages is limited as
                    permitted by law.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>9</span> Indemnification
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    You agree to indemnify TherapAide for any claims arising from your use of the platform,
                    violation of these Terms, or infringement of rights.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>10</span> Governing Law and Dispute Resolution
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    These Terms are governed by the laws of <ExternalLink href="https://staging.therapaide.com/">
                    Your Jurisdiction
                  </ExternalLink>. Any disputes will be
                    resolved through <ExternalLink href="https://staging.therapaide.com/">
                    arbitration/mediation
                  </ExternalLink> in <ExternalLink href="https://staging.therapaide.com/">
                    Your Jurisdiction
                  </ExternalLink>.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>11</span> Contact Information
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    Contact Information For support and inquiries, please contact us at  <ExternalLink href="mailto:support@therapaide.com">
                    support@therapaide.com
                  </ExternalLink>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

TermsOfServicePageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return {
    pageAssetsData,
    inProgress,
    error,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const TermsOfServicePage = compose(connect(mapStateToProps))(TermsOfServicePageComponent);

const TOS_ASSET_NAME = ASSET_NAME;
export { TOS_ASSET_NAME, TermsOfServicePageComponent, TermsOfServiceContent };

export default TermsOfServicePage;
