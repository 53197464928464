import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Page, LayoutSingleColumn, ExternalLink } from '../../components';

import { propTypes } from '../../util/types';

import { H1, H2, H3 } from '../PageBuilder/Primitives/Heading';
import FallbackPage, { fallbackSections } from './FallbackPage';
import { ASSET_NAME } from './PrivacyPolicyPage.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import css from './PrivacyPolicyPage.module.css';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

// This "content-only" component can be used in modals etc.
const PrivacyPolicyContent = props => {
  const { inProgress, error, data } = props;

  if (inProgress) {
    return null;
  }

  // We don't want to add h1 heading twice to the HTML (SEO issue).
  // Modal's header is mapped as h2
  const hasContent = data => typeof data?.content === 'string';
  const exposeContentAsChildren = data => {
    return hasContent(data) ? { children: data.content } : {};
  };
  const CustomHeading1 = props => <H1 as="h2" {...props} />;

  const hasData = error === null && data;
  const sectionsData = hasData ? data : fallbackSections;

  return (
    <SectionBuilder
      {...sectionsData}
      options={{
        fieldComponents: {
          heading1: { component: CustomHeading1, pickValidProps: exposeContentAsChildren },
        },
        isInsideContainer: true,
      }}
    />
  );
};

// Presentational component for PrivacyPolicyPage
const PrivacyPolicyPageComponent = props => {
  const { pageAssetsData, inProgress, error, scrollingDisabled } = props;

  return (
    // <PageBuilder
    //   pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
    //   inProgress={inProgress}
    //   error={error}
    //   fallbackPage={<FallbackPage />}
    // />
    <Page title={'Privacy Policy Page'} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.pageSection}>
          <div className={css.section}>
            <H1 className={css.mainTitle}>Privacy Policy</H1>
            <H2 className={css.subTitle}>
              <span>Effective Date: </span> <span className={css.colored}> Insert Effective Date</span>
            </H2>

            <div className={css.list}>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>1</span>Introduction
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    TherapAide ("we," "us," or "our") is committed to protecting your privacy. This
                    Privacy Policy explains how we collect, use, disclose, and safeguard your
                    information when you visit our website <ExternalLink href="https://therapaide.com/">
                    www.therapaide.com
                  </ExternalLink> (the "Site") and use
                    our services. By accessing or using the Site, you agree to the terms of this
                    Privacy Policy.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>2</span>Information We Collect
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    We may collect and process the following types of information
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>3</span>Personal Information:
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Contact Information:  </span> Name, email address,
                    phone number, and mailing address.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Professional Information:  </span> License number,
                    certifications, professional website, and biography.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Payment Information:  </span> Credit card details and
                    other payment information for transactions.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>4</span>Usage Data:
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Log Information: </span> IP address, browser type,
                    access times, pages viewed, and the website you visited before navigating to our
                    Site.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Cookies and Similar Technologies: </span> We use
                    cookies to collect data about your interactions with our Site.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>5</span>How We Use Your Information
                  <br />
                  We use the information we collect in the following ways:
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    <span className={css.bold}>To Provide and Manage Services: </span> To facilitate
                    account creation, process transactions, and provide customer support.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>To Improve Our Services:  </span>
                    To understand how users interact with our Site and improve functionality and
                    user experience.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>To Communicate with You:  </span>
                    To send you updates, promotional materials, and other information related to
                    your use of our services.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>For Security and Fraud Prevention: </span>
                    To protect against, investigate, and deter fraudulent or unauthorized
                    activities.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>To Comply with Legal Obligations:  </span>
                    To fulfill legal and regulatory requirements.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>6</span> Sharing Your Information
                  <br />
                  We may share your information with third parties in the following circumstances:
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    <span className={css.bold}>With Service Providers: </span> We may share information with third-party vendors who
                    perform services on our behalf, such as payment processing and data analysis.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>For Legal Reasons: </span> We may disclose information if required by law or in response
                    to legal process, such as a court order or subpoena.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Business Transfers: </span> We may share or transfer information in connection with a
                    merger, sale, or acquisition of all or a portion of our business.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>7</span> Data Security
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    We implement appropriate technical and organizational measures to protect your
                    personal information against unauthorized access, alteration, disclosure, or
                    destruction. However, no internet or email transmission is ever fully secure or
                    error-free, so please take special care in deciding what information you send to
                    us in this way.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>8</span>Your Data Rights
                  <br /> Depending on your jurisdiction, you may have the following rights
                  concerning your personal data:
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Access: </span> You have the right to request access to the personal information we hold
                    about you.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Correction: </span> You have the right to request that we correct any inaccuracies in
                    your personal information.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Deletion: </span> You have the right to request that we delete your personal information
                    under certain circumstances.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Objection: </span> You have the right to object to our processing of your personal
                    information.
                  </li>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Data Portability: </span> You have the right to request a copy of your personal data in
                    a structured, commonly used, and machine-readable format.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>9</span> Third-Party Websites
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    Our Site may contain links to third-party websites. We are not responsible for
                    the privacy practices or content of these sites. We encourage you to review the
                    privacy policies of any third-party sites you visit.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>10</span> Children's Privacy
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    Our services are not intended for individuals under the age of 18. We do not
                    knowingly collect personal information from children under 18. If we become
                    aware that we have collected personal information from a child under 18, we will
                    take steps to delete such information.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>11</span> Changes to This Privacy Policy
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    We may update this Privacy Policy from time to time to reflect changes in our
                    practices or legal requirements. We will notify you of any significant changes
                    by posting the new Privacy Policy on our Site and updating the effective date.
                    Your continued use of the Site after such changes constitutes your acceptance of
                    the new Privacy Policy.
                  </li>
                </ul>
              </div>
              <div className={css.item}>
                <H3 className={css.itemTitle}>
                  <span className={css.titleNumber}>12</span>Contact Us
                  <br />
                  If you have any questions or concerns about this Privacy Policy or our data
                  practices, please contact us at:
                </H3>
                <ul className={css.innerList}>
                  <li className={css.innerItem}>
                    <span className={css.bold}>Email: </span>
                    <ExternalLink href="mailto:support@therapaide.com">
                      support@therapaide.com
                    </ExternalLink>
                  </li>
                  <li className={css.innerItem}><ExternalLink href="mailto:support@therapaide.com">
                    Mailing Address
                  </ExternalLink></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

PrivacyPolicyPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return {
    pageAssetsData,
    inProgress,
    error,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PrivacyPolicyPage = compose(connect(mapStateToProps))(PrivacyPolicyPageComponent);

const PRIVACY_POLICY_ASSET_NAME = ASSET_NAME;
export { PRIVACY_POLICY_ASSET_NAME, PrivacyPolicyPageComponent, PrivacyPolicyContent };

export default PrivacyPolicyPage;
